import React, { useEffect, useState } from "react";
import { useComparison } from '../compare_colleges/CompareContext/CompareContext';
import { IoGitCompareOutline } from "react-icons/io5";
import { FaTimes } from 'react-icons/fa';
import { GoPlusCircle } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { Badge } from "react-bootstrap";

const Compare = () => {
    const history = useHistory();
    const [colleges, setColleges] = useState([]);
    const [showDropdown, setShowDropdown] = useState([false, false, false]);
    const [selectedCollege, setSelectedCollege] = useState([null, null, null]);
    const [searchQuery, setSearchQuery] = useState(['', '', '']);
    const { setComparisonData } = useComparison();

    useEffect(() => {
        fetch('https://webcrmapi.nayeedisha.in/api/university-details')
            .then(response => response.json())
            .then(data => {
                setColleges(data);
                // Pre-select a random college for the first slot
                if (data.length > 0) {
                    const randomCollege = data[Math.floor(Math.random() * data.length)];
                    setSelectedCollege([randomCollege, null, null]);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleAddCollegeClick = (index) => {
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? !value : value));
        setShowDropdown(newShowDropdown);
    };

    const handleCollegeChange = (index, college) => {
        if (selectedCollege.includes(college)) {
            alert('This University has already been selected.');
            return;
        }
        const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? college : value));
        setSelectedCollege(newSelectedCollege);
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
        setShowDropdown(newShowDropdown);
        setSearchQuery(searchQuery.map((value, idx) => (idx === index ? '' : value)));
    };

    const handleRemoveCollege = (index) => {
        const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
        const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? null : value));
        setShowDropdown(newShowDropdown);
        setSelectedCollege(newSelectedCollege);
    };

    const handleSearchChange = (index, query) => {
        const newSearchQuery = searchQuery.map((value, idx) => (idx === index ? query : value));
        setSearchQuery(newSearchQuery);
    };

    const filteredColleges = (index) => {
        return colleges.filter(college =>
            college.name.toLowerCase().includes(searchQuery[index].toLowerCase())
        );
    };

    const handleCompareNow = () => {
        const selectedColleges = selectedCollege.filter(college => college);
        if (selectedColleges.length < 2) {
            alert('Please select at least two colleges for comparison.');
            return;
        }

        const selectedUids = selectedColleges.map(college => college.uid);
        const collegeNames = selectedColleges.map(college => college.name).join('-vs-');

        const data = {
            university_ids: selectedUids
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        fetch('https://webcrmapi.nayeedisha.in/api/compare-universities', requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('API Response:', result);

                // Save the comparison data to local storage
                localStorage.setItem('comparisonData', JSON.stringify(result));

                // Set the comparison data in context
                setComparisonData(result);

                // Construct the URL based on college names
                const urlSlug = collegeNames.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
                history.push(`/compare/${urlSlug}`);
            })
            .catch(error => {
                console.error('Error comparing universities:', error);
            });
    };

    return (
        <section className="compare_colleges" id="compare">
            <div className="container mt-4">
                <div className="text-center mt-4">
                    <h3></h3>
                    <h3 className="text-center" id="heads">
                        <span id="explore">Compare </span> Colleges
                    </h3>
                    <p className="text-center" id="my-p">
                    Choosing the right college is a crucial decision that can significantly impact your future. With numerous colleges offering diverse courses and facilities, comparing them helps you identify the best fit for your academic and personal goals. Here are some key factors to consider while comparing colleges
                    </p>
                </div>
                <div className="row mt-4">
                    {selectedCollege.map((college, index) => (
                        <div className="col-md-4 mt-2" key={index}>
                            <div className="compare ">
                                {college ? (
                                    
                                    <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                    
                                        <button className="compare_closebutton" onClick={() => handleRemoveCollege(index)}>
                                            <FaTimes />
                                        </button>
                                        <div
                                            className="university-section "
                                            style={{
                                                width: '100%',
                                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${college.cover_image})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: '100% 186%',
                                                height: '100px',
                                                backgroundPosition: 'center'
                                            }}
                                        >

                                        </div>
                                        <div className="card-body comparetext">
                                            <img src={college.logo} className="" alt="Nayeedisha" />
                                            <h5 className="mt-2 f-16 text-transform">{college.name}</h5>
                                            <div>
                                                <div className="pt-1">
                                                    <p className="para1" style={{ color: '#000' }}>
                                                        Country -
                                                        <span className="ugc" style={{ color: '#000' }}>
                                                            {college.country ? college.country : 'Not found'}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="text-center">
                                            <GoPlusCircle className="plusicon" onClick={() => handleAddCollegeClick(index)} />
                                            <p onClick={() => handleAddCollegeClick(index)}>Add College</p>
                                            {showDropdown[index] && (
                                                <div className="custom-dropdown">
                                                    <input
                                                        type="text"
                                                        value={searchQuery[index]}
                                                        onChange={(e) => handleSearchChange(index, e.target.value)}
                                                        placeholder="Search college by name"
                                                        className="dropdown-input input_compair"
                                                        disabled={index > 0 && !selectedCollege[index - 1]}
                                                    />
                                                    <div className="dropdown-list compare_dropdown">
                                                        {filteredColleges(index).map(college => (
                                                            <div
                                                                key={college.uid}
                                                                className="dropdown-item compare_itemlist"
                                                                onClick={() => handleCollegeChange(index, college)}
                                                            >
                                                                {college.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <br />
                <div className="text-center mt-2">
                    <button
                        type="button"
                        className="btn btn-primary mt-3"
                        disabled={!selectedCollege[0]}
                        onClick={handleCompareNow}
                    >
                        Compare Now <IoGitCompareOutline />
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Compare;
