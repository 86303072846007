import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import ContactUs from "../pages/Contact";
import Home from "../pages/home";
import Collegelist from "../pages/Collegelist";
import Collegelist2 from "../pages/collegelist2";
import University from "../pages/University";
import Contactus from "../pages/contactus";
import Aboutus from "../pages/aboutus";
import Services from "../pages/services";
import Bashkir from "../pages/pagerussia/index";
import Chuvash from "../pages/pagerussia/mbbs-in-chuvash-state-medical-university-russia";
import Omsk from "../pages/pagerussia/mbbs-in-omsk-state-medical-university-russia";
import Crimeafedera from "../pages/pagerussia/mbbs-in-crimea-federal-university-russia";
import Brokenshire from "../pages/pagephilippines/mbbs-in-brokenshire-college-of-medicine-philippines";
import Ladyoffatima from "../pages/pagephilippines/mbbs-in-our-lady-of-fatima-university-philippines";
import Jalalabad from "../pages/pagekyrgyzystan/mbbs-in-jalalabad-state-university-kyrgyzstan";
import KazakhUniversity from "../pages/pagekyrgyzystan/mbbs-in-kazakh-national-medical-university-kyrgyzstan";
import OshUniversityKyrgyzstan from "../pages/pagekyrgyzystan/mbbs-in-osh-state-medical-university-kyrgyzstan";
import UniversityShanghaiChina from "../pages/pagechina/mbbs-in-fudan-university-shanghai-medical-college-china";
import PekingUniversityChina from "../pages/pagechina/mbbs-in-peking-university-health-science-center-china";
import ZhejiangUniversityChina from "../pages/pagechina/mbbs-in-zhejiang-university-school-of-medicine-china";
import DhakaBangladesh from "../pages/pagebangladesh/mbbs-in-dhaka-national-medical-college-bangladesh";
import MBBSEasternBangladesh from "../pages/pagebangladesh/mbbs-in-eastern-medical-college-bangladesh";
import Holyfamilybangladesh from "../pages/pagebangladesh/mbbs-in-holy-family-red-crescent-medical-college-bangladesh";
import KhwajaBangladesh from "../pages/pagebangladesh/mbbs-in-khwaja-yunus-ali -medical-college-bangladesh";
import CommunityBangladesh from "../pages/pagebangladesh/mbbs-in-community-based-medical-college-bangladesh";
import AIUniversityKazakhstan from "../pages/pageKazakhstan/mbbs-in-ai-farabi-kazakh-national-university-kazakhstan";
import AstanaKazakhstan from "../pages/pageKazakhstan/mbbs-in-astana-medical-university-kazakhstan";
import InternationalKazakhstan from "../pages/pageKazakhstan/mbbs-in-international-medical-school-kazakhstan";
import KazakhKazakhstan from "../pages/pageKazakhstan/mbbs-in-kazakh-national-medical-university-kazakhstan";
import KokshetauKazakhstan from "../pages/pageKazakhstan/mbbs-in-kokshetau-state-university-kazakhstan";
import KazakhstanKazakhstan from "../pages/pageKazakhstan/mbbs-in-south-kazakhstan-medical-academy-kazakhstan";
import Universityofperpetual from "../pages/pagephilippines/mbbs-in-university-of-perpetual-help-system-dalta-philippines";
import Exam from '../exam-details/index'
import ExamDetails from '../exam-details/exam'
import News from '../pages/news/index'
import NewsDetails from '../pages/news/news-details'
import Registration from '../pages/registration-steper/index'
import IndiaMBBS from "../pages/india/mbbs-india";
import IndiaEngineering from "../pages/india/engineering-india";
import ThankYou from "../pages/thank-you/index";
import CallBackRequest from "../pages/request-callback/index"
import PrivacyPolicy from "../pages/Privacy_Policy/index"
import Terms_of_Use from "../pages/Terms_of_Use/index"
import UniversityDetails from "../pages/StudyAbroad";
import Blog from '../blogs/index'
import BlogList from '../blogs/blog-details'
import NotFoundPage from '../pages/NotFoundPage'; 
import CompareColleges from "../pages/compare_colleges/index"
import { ComparisonProvider } from '../pages/compare_colleges/CompareContext/CompareContext';

const Routing = () => {
  return (
    <ComparisonProvider>
    <Switch>
    <Route path="/compare/:colleges" component={CompareColleges} />
        <Route exact path="/" component={Home} />
        <Route path="/request-callback" component={CallBackRequest} />
        <Route path="/thank-you" component={ThankYou} />
        <Route path="/engineering-india" component={IndiaEngineering} />
        <Route path="/mbbs-india" component={IndiaMBBS} />
        <Route path="/registration" component={Registration} />
        <Route path="/news-details/:slug" component={NewsDetails} />
        <Route path="/news" component={News} />
        <Route path="/exams" component={Exam} />
        <Route path="/study-abroad" component={UniversityDetails} />
        <Route path="/exams-details/:slug" component={ExamDetails} />
        <Route path="/university/:uid" component={University} />
        <Route path="/404" component={NotFoundPage}/>
        <Route path="/contact-us" component={Contactus} />
        <Route path="/about-us" component={Aboutus} />
        <Route path="/services" component={Services} />
        <Route path="/blogs/:slug" component={BlogList} />
        <Route path="/blogs" component={Blog} />
        <Route exact path="/mbbs-in-bashkir-state-medical-university" component={Bashkir} />
        <Route path="/mbbs-in-chuvash-state-medical-university-russia" component={Chuvash} />
        <Route path="/mbbs-in-omsk-state-medical-university-russia" component={Omsk} />
        <Route path="/mbbs-in-crimea-federal-university-russia" component={Crimeafedera} />
        <Route path="/mbbs-in-brokenshire-college-of-medicine-philippines" component={Brokenshire} />
        <Route path="/mbbs-in-our-lady-of-fatima-university-philippines" component={Ladyoffatima} />
        <Route path="/mbbs-in-university-of-perpetual-help-system-dalta-philippines" component={Universityofperpetual} />
        <Route path="/mbbs-in-jalalabad-state-university-kyrgyzstan" component={Jalalabad} />
        <Route path="/mbbs-in-kazakh-national-medical-university-kyrgyzstan" component={KazakhUniversity} />
        <Route path="/mbbs-in-osh-state-medical-university-kyrgyzstan" component={OshUniversityKyrgyzstan} />
        <Route path="/mbbs-in-fudan-university-shanghai-medical-college-china" component={UniversityShanghaiChina} />
        <Route path="/mbbs-in-peking-university-health-science-center-china" component={PekingUniversityChina} />
        <Route path="/mbbs-in-zhejiang-university-school-of-medicine-china" component={ZhejiangUniversityChina} />
        <Route path="/mbbs-in-dhaka-national-medical-college-bangladesh" component={DhakaBangladesh} />
        <Route path="/mbbs-in-eastern-medical-college-bangladesh" component={MBBSEasternBangladesh} />
        <Route path="/mbbs-in-khwaja-yunus-ali-medical-college-bangladesh" component={KhwajaBangladesh} />
        <Route path="/mbbs-in-holy-family-red-crescent-medical-college-bangladesh" component={Holyfamilybangladesh} />
        <Route path="/mbbs-in-community-based-medical-college-bangladesh" component={CommunityBangladesh} />
        <Route path="/mbbs-in-ai-farabi-kazakh-national-university-kazakhstan" component={AIUniversityKazakhstan} />
        <Route path="/mbbs-in-astana-medical-university-kazakhstan" component={AstanaKazakhstan} />
        <Route path="/mbbs-in-international-medical-school-kazakhstan" component={InternationalKazakhstan} />
        <Route path="/mbbs-in-kazakh-national-medical-university-kazakhstan" component={KazakhKazakhstan} />
        <Route path="/mbbs-in-kokshetau-state-university-kazakhstan" component={KokshetauKazakhstan} />
        <Route path="/mbbs-in-south-kazakhstan-medical-academy-kazakhstan" component={KazakhstanKazakhstan} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" component={Terms_of_Use} />        
        <Route component={NotFoundPage} /> {/* This should always be the last route */}
      </Switch>
      </ComparisonProvider>
  );
};

export default Routing;
