import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import {Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill,Bs7CircleFill,Bs8CircleFill,Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
const Chauvas = () => {
    const options = {
        loop: true,
        margin: 10,
        items: 1,
        autoplay: true,
        responsive: {
           0: {
              items: 1,
              nav:true,
              
           },
           600: {
              items: 3,
           },
           1000: {
              items: 4,
           },
        },
     };
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       fetch('https://webcrmapi.nayeedisha.in/api/university-details')
          .then((response) => response.json())
          .then((data) => {
             // Assuming 'results' contains the array of universities
             setUniversities(data);
             setIsLoading(false);

          })
          .catch((error) => {
             setIsLoading(false);
             console.error('Error fetching data:', error);
          });
    }, []);
    return (
        <>



            {/* section1 */}

            <div className='abt-section pt-5 pb-5'>


                <div className='container'>

                    <h3>MBBS in Chuvash State Medical University, Russia</h3>

                    <div className='bds'></div>
                    <div id='brdrs'></div>

                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>




            <div className='container abt-2 mt-5 mb-5'>


                <p className='mb-4'>Welcome to Nayee Disha, your gateway to fulfilling your dream of pursuing a career in medicine! We are delighted to present Chuvash State Medical University, an exceptional institution nestled in the picturesque landscapes of Russia. Renowned for its world-class education and extensive experience in producing proficient medical professionals, Chuvash State Medical University offers a remarkable opportunity for Indian students to embark on a transformative journey towards becoming successful doctors. With its state-of-the-art facilities, internationally recognized faculty, and a vibrant multicultural environment, this esteemed university provides an ideal setting for aspiring doctors to excel academically and broaden their horizons. Join us as we delve into the enticing realm of MBBS education at Chuvash State Medical University and discover the countless possibilities that await you on this extraordinary path to a promising medical career.

</p>
               
                <hr></hr>
                <div className='py-3 rounded'>

                    <div className='container mt-3'>
                        <h3 className='border_before'>Advantages of MBBS in Chuvash State Medical University, Russia</h3>
                        <div className='row'>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height  p-3 rounded'>
                                    <b className='f-19'>Global Recognition</b>
                                    <p className='f-17'>Acclaimed Degrees Opening the International Doors and Precious Chances</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Affordable Education</b>
                                    <p className='f-17'>Cost-Effective MBBS Programs without Compromising Quality and Rigour</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Experienced Faculty</b>
                                    <p className='f-17'>Renowned Professors Ensuring Comprehensive Medical Education Is Provided</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Cultural Diversity</b>
                                    <p className='f-17'>Exposure to Multicultural Environment Enhancing Global Perspective and Exposure</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='container mb-4 mt-3'>
                        <div className='row'>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                            
                                

                                    <b className='f-19'>Advanced Infrastructure</b>
                                    <p className='f-17'>State-of-the-Art Facilities Are Provided Cutting-Edge Learning Opportunities</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>High-Quality Teaching</b>
                                    <p className='f-17'>Rigorous Curriculum and Practical Training for Competent Medical Professionals</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>English-Medium</b>
                                    <p className='f-17'>Language Barrier-Free Education Facilitating Easy Learning and Communication</p>
                                </div>

                            </div>
                            <div className='col-md-3 mt-4 '>
                                <div className='bg-coloringrideant card-height p-3 rounded'>
                                    <b className='f-19'>Career Opportunities</b>
                                    <p className='f-17'>Seamless Pathway to Explore Employment Options in Russia and Beyond.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <hr></hr>




            </div>
            <div className='container'>
                <h3 className='border_before'>Facts about Chuvash State Medical University, Russia</h3>
                <div className='row'>
                    <div className='col-md-12'>
                        <ul className='list-none mt-2'>
                            <li className='mt-1 '><Bs1CircleFill />&nbsp; Established Excellence: Chuvash State Medical University has a rich history spanning over six decades, consistently maintaining a reputation for academic excellence and innovation in medical education.</li>
                            <li className='mt-2 '> <Bs2CircleFill /> &nbsp; Recognized by WHO and MCI: The university is listed in the World Health Organization (WHO) directory of medical schools and is recognized by the Medical Council of India (MCI), ensuring that your degree is globally accepted.</li>
                            <li className='mt-2 '> <Bs3CircleFill />&nbsp; Multidisciplinary Approach: With a comprehensive range of medical programs, Chuvash State Medical University offers diverse specializations, including General Medicine, Dentistry, Pediatrics, Pharmacy, and Nursing, allowing students to pursue their specific areas of interest.</li>
                            <li className='mt-2 '> <Bs4CircleFill /> &nbsp;Modern Infrastructure: The university boasts modern facilities, well-equipped laboratories, cutting-edge technology, and advanced teaching aids that provide students with a conducive learning environment.</li>
                            <li className='mt-2 '> <Bs5CircleFill /> &nbsp;Experienced Faculty: At Chuvash State Medical University, you will be guided by a highly experienced and accomplished faculty comprising renowned medical professionals, researchers, and practitioners.</li>
                            <li className='mt-2 '> <Bs6CircleFill /> &nbsp;Clinical Training: The university has collaborations with prestigious medical institutions and hospitals, providing ample opportunities for clinical exposure and hands-on training to enhance practical skills.</li>
                            <li  className='mt-2 '><Bs7CircleFill /> &nbsp;Research Opportunities: Chuvash State Medical University encourages students to engage in research activities, fostering a culture of innovation and contributing to advancements in medical science.</li>
                            <li  className='mt-2 '><Bs8CircleFill /> &nbsp;International Student Community: Being a preferred choice for students from around the world, the university offers a vibrant and diverse community, promoting cultural exchange and global networking opportunities.</li>
                            <li  className='mt-2 '><Bs9CircleFill /> &nbsp;Affordable Tuition Fees: Compared to other medical universities, Chuvash State Medical University offers competitive tuition fees, making it an attractive option for Indian students seeking quality education at an affordable cost.</li>
                            
                        </ul>
                    </div>

                </div>
            </div>
<hr></hr>
<div className='container'>
                <h3 className='border_before'>Admission Process and Documents Needed</h3>
                <div className='row'>
                    <div className='col-md-12'>
                      
                    </div>

                </div>
            </div>

<div className='container'>

<img src={Admissionimg} alt="img"  className='w-100 mt-3'/>
</div>

<br></br>

        </>
    );
};

export default Chauvas;
